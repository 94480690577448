// src/router.js
import {
    createRouter,
    createWebHistory
} from 'vue-router'

import HomePage from '@/views/HomePage.vue'
import ShopPage from '@/views/ShopPage.vue'
import BuilderPage from '@/views/BuilderPage.vue'
import ImprintPage from '@/views/ImprintPage.vue'
import TermsPage from '@/views/TermsPage.vue'
import AboutPage from '@/views/AboutPage.vue'
import ContactPage from '@/views/ContactPage.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/:catchAll(.*)',
            component: HomePage
        },
        {
            path: '/',
            component: HomePage
        },
        {
            path: '/about',
            component: AboutPage
        },
        {
            path: '/shop',
            component: ShopPage
        },
        {
            path: '/builder',
            component: BuilderPage
        },
        {
            path: '/imprint',
            component: ImprintPage
        },
        {
            path: '/terms',
            component: TermsPage
        },
        {
            path: '/contact',
            component: ContactPage
        }
    ],
})

export default router