<template>
    <div class="image-wrapper">
        <img loading="lazy" :src="imageSrc" :alt="imageAlt" />
    </div>
    <section class="main-container">
        <div class="content-wrapper">
            <div class="column title-column">
                <h1 class="title">{{ title }}</h1>
            </div>
            <div class="column description-column">
                <div class="description">
                    <p class="description-text">{{ description }}</p>
                    <div class="button-wrapper">
                        <ButtonComponent :isPrimary="true" label="Browse" @click="handleBrowseClick" />
                        <ButtonComponent :isPrimary="false" label="Get Started" @click="handleGetStartedClick" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
    components: {
        ButtonComponent,
    },
    props: {
        title: {
            type: String,
            default: 'Discover the World of Digital Art',
        },
        description: {
            type: String,
            default: 'Explore our curated collection of stunning digital art and unleash your creativity.',
        },
        imageSrc: {
            type: String,
            default: require('@/assets/PlaceholderImage.png'),
        },
        imageAlt: {
            type: String,
            default: 'Placeholder Image',
        },
        linkBrowse: {
            type: String,
            default: '/shop',
        },
        linkGetStarted: {
            type: String,
            default: '/bot-services',
        },
    },
    methods: {
        handleBrowseClick() {
            // Handle browse button click event
            window.location.href = this.linkBrowse;
        },
        handleGetStartedClick() {
            // Handle get started button click event
            window.location.href = this.linkGetStarted;
        },
    },
}
</script>

<style scoped>

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image-wrapper {
    width: 100%;
    display: flex;
    align-self: stretch;
    height: 500px;
}

img {
    width: 100%; /* Stretch the image horizontally */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Or use 'contain' depending on your needs */
    display: block; /* Remove default spacing under the image */
}

.image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.content-wrapper {
    display: flex;
    width: 100%;
    gap: 5rem;
    padding-top: 0;
}

.column {
    width: 50%;
}

.title {
    color: var(--primary-color);
    font-size: 3.5rem;
    font-weight: bold;
}

.description-text {
    color: var(--primary-color);
    font-size: 1.125rem;
}

.button-wrapper {
    margin-top: 20px;
    display: flex;
    gap: 16px;
}

@media (max-width: 991px) {
    .content-wrapper {
        flex-direction: column;
    }

    .column {
        width: 100%;
    }

    .button-wrapper {
        flex-direction: row;
    }
}
</style>
