<template>
    <BigHeaderComponent :title="discover.title" :description="discover.description" :imageSrc="discover.imageSrc"
    :imageAlt="discover.imageAlt" />
</template>

<script>
import BigHeaderComponent from '@/components/builder/BigHeaderComponent.vue'

export default {
    name: 'AboutUsPage',
    components: {
        BigHeaderComponent,
    },
    data() {
        return {
            discover: {
                title: 'Discover the World of Digital Art and Discord Bots',
                description: `6static offers unique digital art products and discord bot services.`,
                imageSrc: require('@/assets/images/m6static_Cover_002.jpg'),
                imageAlt: 'm6static Cover 002',
            },
        }
    }
}
</script>