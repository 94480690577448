<template>
    <BigHeaderComponent :imageSrc="header.imageSrc" :imageAlt="header.imageAlt" />
</template>
<script>
import BigHeaderComponent from '@/components/builder/BigHeaderComponent.vue'

export default {
    name: 'ShopPage',
    components: {
        BigHeaderComponent,
    },
    data() {
        return {
            header: {
                imageSrc: require('@/assets/images/m6static_Cover_004.jpg'),
                imageAlt: 'm6static Cover',
            },
        }
    },
}
</script>