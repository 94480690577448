<template>
    <div class="main-container">
        <section class="section-container">
            <header class="header">
                <h1 class="title">{{ title }}</h1>
            </header>
            <h2 class="section-title">{{ sectionTitle }}</h2>
            <p class="section-description">{{ sectionDescription }}</p>
        </section>
        <section :class="featuresContainerClass">
            <div
                v-for="feature in features"
                :key="feature.title"
                class="feature">
                <div class="feature-image">
                    <img
                        loading="lazy"
                        :src="
                            feature.imageSrc ||
                            require('@/assets/PlaceholderImage.png')
                        "
                        :alt="
                            feature.imageSrc
                                ? feature.imageAlt
                                : 'Placeholder Image'
                        "
                        :class="imageClass" />
                </div>
                <div class="feature-content">
                    <h3 class="feature-title">{{ feature.title }}</h3>
                    <p class="feature-description">{{ feature.description }}</p>
                </div>
            </div>
        </section>
        <footer>
            <div class="button-container">
                <LearnMoreButton
                    :label="'Learn More'"
                    :targetUrl="learnMoreUrl" />
                <SignUpButton :label="'Sign Up'" :targetUrl="signUpUrl" />
            </div>
        </footer>
    </div>
</template>

<script>
import LearnMoreButton from "@/components/LearnMoreButton.vue"
import SignUpButton from "@/components/SignUpButton.vue"

export default {
    components: {
        LearnMoreButton,
        SignUpButton,
    },
    methods: {
        handleLearnMore() {
            // Handle Learn More button click event
            window.location.href = this.learnMoreUrl
        },
        handleSignUp() {
            // Handle Sign Up button click event
            window.location.href = this.signUpUrl
        },
    },
    data() {
        return {
            title: "Feature Showcase",
        }
    },
    props: {
        sectionTitle: {
            type: String,
            default:
                "Discover the World of Digital Art and Unleash Your Creativity",
        },
        sectionDescription: {
            type: String,
            default: `At 6static, we offer a wide range of unique digital art products and services that cater to all your creative needs. Whether you're an artist looking to showcase your work or a buyer searching for one-of-a-kind pieces, our platform is designed to connect you with the best talent and artwork in the industry. Join our community today and experience the endless possibilities of digital art.`,
        },
        features: {
            type: Array,
            required: true,
        },
        learnMoreUrl: {
            type: String,
            default: "#learnmore",
        },
        signUpUrl: {
            type: String,
            default: "#signup",
        },
        noMaxWidth: {
            type: Boolean,
            default: false,
        },
        imageWide: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        featuresContainerClass() {
            return {
                "features-container": true,
                "pls-no-max": this.noMaxWidth,
            }
        },
        imageClass() {
            return {
                "image": true,
                "wide-image": this.imageWide,
            }
        },
    },
}
</script>

<style scoped>
.main-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.header {
    text-align: center;
}

.title {
    color: var(--primary-color, #000);
    font: 600 1rem/150% var(--secondary-font-family, sans-serif);
}

.section-container {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 768px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .section-container {
        margin-top: 40px;
        flex-direction: column;
        display: flex;
    }
}

.section-title {
    color: var(--primary-color, #000);
    text-align: center;
    font: 700 3rem/120% var(--secondary-font-family, sans-serif);
}

@media (max-width: 991px) {
    .section-title {
        max-width: 100%;
    }
}

.section-description {
    color: var(--primary-color, #000);
    text-align: center;
    margin-top: 2rem;
    font: 400 1.125rem/150% var(--secondary-font-family, sans-serif);
}

@media (max-width: 991px) {
    .section-description {
        max-width: 100%;
    }
}

.features-container {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.features-container:not(.pls-no-max) {
    width: 100%;
    max-width: 1312px;
}

@media (max-width: 991px) {
    .features-container {
        max-width: 100%;
        margin: 40px 0;
        flex-direction: column;
        gap: 1.5rem;
    }
}

.feature {
    justify-content: center;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

@media (max-width: 991px) {
    .feature {
        flex-direction: column;
        align-items: stretch;
        gap: 1.5rem;
    }
}

.feature-image {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    .feature-image {
        margin-top: 40px;
    }
}

.image {
    aspect-ratio: 1.69;
    object-fit: cover;
    object-position: center;
    width: 100%;
    align-self: stretch;
    overflow: hidden;
}

.wide-image {
    width: 400px;
}

.feature-content {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 1.5rem;
}

.feature-title {
    color: var(--primary-color, #000);
    font: 700 2rem/130% var(--secondary-font-family, sans-serif);
}

.feature-description {
    color: var(--primary-color, #000);
    font: 400 1rem/150% var(--secondary-font-family, sans-serif);
    text-align: center;
}

footer {
    align-items: flex-start;
    align-self: center;
    display: flex;
    max-width: 100%;
    gap: 20px;
}

@media (max-width: 991px) {
    .footer {
        margin-top: 40px;
    }
}

.button-container {
    display: flex;
    gap: 1rem;
}
</style>
