<template>
    <NavigationComponent />
    <ShopPage v-if="isRoute('/shop')" />
    <BuilderPage v-else-if="isRoute('/builder')" />
    <ImprintPage v-else-if="isRoute('/imprint')" />
    <TermsPage v-else-if="isRoute('/terms')" />
    <AboutUsPage v-else-if="isRoute('/about')" />
    <ContactPage v-else-if="isRoute('/contact')" />
    <BotServicesPage v-else-if="isRoute('/bot-services')" />
    <HomePage v-else />
    <TrustLogo v-if="showTrustLogo" />
    <FooterComponent />
</template>

<script>
import NavigationComponent from '@/components/builder/NavigationComponent.vue'
import TrustLogo from '@/components/TrustLogo.vue'
import FooterComponent from '@/components/builder/FooterComponent.vue'
import HomePage from '@/views/HomePage.vue'
import ShopPage from '@/views/ShopPage.vue'
import BuilderPage from '@/views/BuilderPage.vue'
import ImprintPage from '@/views/ImprintPage.vue'
import TermsPage from '@/views/TermsPage.vue'
import AboutUsPage from '@/views/AboutUsPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import BotServicesPage from '@/views/BotServicesPage.vue'

export default {
    name: 'App',
    components: {
        NavigationComponent,
        FooterComponent,
        TrustLogo,
        HomePage,
        ShopPage,
        BuilderPage,
        ImprintPage,
        TermsPage,
        AboutUsPage,
        ContactPage,
        BotServicesPage,
    },
    methods: {
        isRoute(route) {
            return this.$route.path === route
        }
    },
    data() {
        return {
            showTrustLogo: false,
        }
    },
}
</script>

<style>
#app {
    font-family: var(--primary-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--background-color);
}
</style>
