<template>
    <header class="main-container">
        <section class="left-logo">
            <HeaderImage class="header-image" />
        </section>
        <section class="left" v-show="isWideScreen || isMenuOpen">
            <nav class="header">
                <h1 class="logo">Discover Art</h1>
                <ButtonComponent :label="'Shop Now'" :isPrimary="false" />
                <div class="nav-items" v-if="isMenuOpen || isWideScreen">
                    <a href="/" @click="handleMenuItemClick">Home</a>
                    <a href="/about" @click="handleMenuItemClick">About</a>
                    <a href="/shop" @click="handleMenuItemClick">Shop</a>
                    <a href="/builder" @click="handleMenuItemClick">Discover</a>
                </div>
            </nav>
            <section class="explore-section dropdown">
                <ButtonComponent :isPrimary="false" :label="'Explore Services'" :borderVisible="false"
                    :imageSrc="require('@/assets/Chevron Down.svg')" :imageAlt="'Explore'" />
                    <div class="dropdown-content">
                        <a href="/bot-services">Bots</a>
                        <a href="/custom-srevices">Custom</a>
                    </div>
            </section>
        </section>
        <section class="middle"></section>
        <section class="right dropdown" style="float:right;">
            <ButtonComponent :isPrimary="false" :label="'Menu'" @click="toggleMenu" />
            <div class="dropdown-content" style="right:0;">
                <a href="/account" @click="handleMenuItemClick">Account</a>
                <a href="/contact" @click="handleMenuItemClick">Contact</a>
                <a href="/imprint" @click="handleMenuItemClick">Imprint</a>
                <a href="/terms" @click="handleMenuItemClick">Terms</a>
            </div>
        </section>
    </header>
</template>

<script>
import HeaderImage from "@/components/HeaderImage.vue"
import ButtonComponent from "@/components/ButtonComponent.vue"

export default {
    components: {
        HeaderImage,
        ButtonComponent,
    },
    data() {
        return {
            isMenuOpen: false,
            isWideScreen: window.innerWidth > 991,
            isDropdownOpen: null,
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen
        },
        handleMenuItemClick() {
            if (!this.isWideScreen) {
                this.isMenuOpen = false
            }
        },
        toggleDropdown(menu) {
            this.isDropdownOpen = this.isDropdownOpen === menu ? null : menu
        },
        handleResize() {
            this.isWideScreen = window.innerWidth > 991
            if (this.isWideScreen) {
                this.isMenuOpen = false
            }
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize)
    },
}
</script>

<style scoped>
.main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 3rem;
    border-bottom: 0.0625rem solid var(--primary-color);
}

.left,
.left-logo,
.right {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.left {
    flex: 1 0 0;
}

.header,
.explore-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;
}

.header {
    margin: 1rem;
    margin-left: 0;
}

.explore-section {
    align-self: stretch;
    gap: 4px;
}

.logo,
.shop-link,
.explore-title,
.menu {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    color: var(--primary-color);
    background-color: var(--background-color);
}

.shop-link,
.menu {
    cursor: pointer;
}

.explore-image,
.header-image {
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    max-width: 100%;
}

.explore-image {
    width: 1.5rem;
    aspect-ratio: 1;
}

.header-image {
    width: 63px;
    aspect-ratio: 2.33;
}

.menu {
    padding: 0.5rem 1.25rem;
    border: 0.0625rem solid var(--primary-color);
    width: 81px;
    max-width: 100%;
    justify-content: center;
}

.nav-items {
    display: none; /* Hide by default */
}

.nav-items ol {
    display: flex;
    list-style: none;
    padding: 0.5rem 1.25rem;
    margin: 0;
}

.nav-items li {
    margin: 0 0.75rem;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown-content {
    display: none;
    /* position: absolute; */
    background-color: var(--background-color);
    color: var(--primary-color);
    text-align: left;
    padding: 0.5rem 1.25rem;
    min-width: 10rem;
    z-index: 1;
}

.dropdown-content a:hover {
    background-color: var(--secondary-color);
}

.dropdown:hover .dropdown-content,
.dropdown.active .dropdown-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start; 
}

/* Display .nav-items when .left section is visible or when menu is open */
@media (min-width: 992px) {
    .nav-items {
        display: flex;
        justify-content: space-between;
        gap: 1.25rem;
        align-items: flex-start;
        flex-direction: row;
    }
}

@media (max-width: 991px) {
    .left {
        display: none;
    }

    .main-container {
        justify-content: space-between;
    }
}
</style>
