<template>
    <button :class="buttonClass" @click="$emit('click')">
        <slot>{{ label }}</slot>
        <component v-if="iconComponent" :is="iconComponent" :color="iconColor" class="button-icon" />
        <img v-if="imageSrc" :src="imageSrc" :alt="imageAlt" class="button-icon" />
    </button>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Button',
        },
        isPrimary: {
            type: Boolean,
            default: true,
        },
        imageSrc: {
            type: String,
            default: null,
        },
        imageAlt: {
            type: String,
            default: "Button Icon",
        },
        borderVisible: {
            type: Boolean,
            default: true,
        },
        iconComponent: {
            type: Object,
            default: null,
        },
        iconColor: {
            type: String,
            default: "var(--primary-color)",
        },
    },
    computed: {
        buttonClass() {
            return {
                'primary-button': this.isPrimary,
                'secondary-button': !this.isPrimary,
                'border-hidden': !this.borderVisible
            }
        }
    },
}
</script>

<style scoped>
@import '@/styles/sharedButton.css';
</style>