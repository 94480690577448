<template>
    <ContactComponent :legendTitle="contact.legendTitle" :options="contact.options" />
</template>
<script>
import ContactComponent from '@/components/builder/ContactComponent.vue'

export default {
    name: 'ContactPage',
    components: {
        ContactComponent,
    },
    data() {
        return {
            contact: {
                legendTitle: null,
                options: {
                    option1: 'Option 1',
                    option2: 'Option 2',
                    option3: 'Option 3',
                    option4: 'Option 4',
                    option5: 'Option 5',
                    option6: 'Option 6',
                },
            }
        }
    },
}
</script>