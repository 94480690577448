<template>
    <div class="main-container">
        <DiscoverComponent :title="'Latest'" :subtitle="'Discover the Art'" :text="'Stay informed with our engaging blog posts'" :isCentered="true"  />
        <section class="articles-container">
            <div v-for="article in articles" :key="article.title" class="article">
                <div class="article-image">
                    <img loading="lazy" :src="article.imageSrc || require('@/assets/PlaceholderImage.png')"
                        :alt="article.imageAlt || 'Placeholder Image'" class="article-img" />
                </div>
                <article class="article-content">
                    <div class="article-meta">
                        <div class="article-category">{{ article.category }}</div>
                        <div class="article-read-time">{{ article.readTime }}</div>
                    </div>
                    <div class="article-title">{{ article.title }}</div>
                    <div class="article-description">{{ article.description }}</div>
                </article>
                <div class="article-actions">
                    <SignUpButton :label="'Read More'" @click="handleButtonClick" />
                </div>
            </div>
        </section>
        <footer>
            <ButtonComponent :isPrimary="false" :label="'Miep'" @click="handleButtonClick" />
        </footer>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import SignUpButton from '@/components/SignUpButton.vue'
import DiscoverComponent from '@/components/DiscoverComponent.vue'

export default {
    name: 'BlogComponent',
    components: {
        ButtonComponent,
        SignUpButton,
        DiscoverComponent,
    },
    props: {
        // articles: {
        //     type: Array,
        //     required: true,
        // },
    },
    methods: {
        handleButtonClick() {
            // Handle button click event here
        },
    },
    data() {
        return {
            articles: [
                {
                    title: 'The Rise of Discord Bots: Revolutionizing Online Communities',
                    description: 'Explore the world of Discord bots and how they are transforming online communities.',
                    category: 'Bot',
                    readTime: '7 min',
                    imageAlt: 'How to Create a Discord Bot',
                },
                {
                    title: 'Unleashing Creativity: The Power of Digital Art',
                    description: 'Discover the endless possibilities of digital art and its impact on creativity.',
                    category: 'Art',
                    readTime: '5 min',
                    imageAlt: 'Digital Art',
                }
            ]
        }
    }
}
</script>

<style scoped>
.main-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.articles-container {
    align-items: center;
    align-self: center;
    display: flex;
    /* width: 100%; */ /* commenting width out, results in true center.. */
    flex-direction: row;
    gap: 3rem;
}

@media (max-width: 991px) {
    .articles-container {
        flex-direction: column;
        display: flex;
        width: 100%;
        max-width: 100%;
    }
}

.article {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    width: 100%;
    max-width: 768px;
    /* max-width: 100%; */
}

.article-image {
    aspect-ratio: 1.69;
    object-fit: cover;
    object-position: center;
    width: 100%;
    align-self: stretch;
    overflow: hidden;
}

.image {
    aspect-ratio: 1.69;
    object-fit: cover;
    object-position: center;
    width: 100%;
    align-self: stretch;
    overflow: hidden;
}

.article-content {
    align-items: flex-start;
    align-self: start;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}

.article-meta {
    display: flex;
    gap: 16px;
    margin-bottom: 0.5rem;
}

.article-category,
.article-read-time {
    font: 600 0.875rem/150% var(--secondary-font-family, sans-serif);
}

.article-title {
    font: 700 1.5rem/140% var(--secondary-font-family, sans-serif);
    margin-bottom: 0.5rem;
}


.article-description {
    font: 400 1rem/150% var(--secondary-font-family, sans-serif);
}

.article-actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

footer {
    align-items: center;
    align-self: center;
    display: flex;
    width: 240px;
    max-width: 100%;
    justify-content: center;
}

@media (max-width: 991px) {

    .latest-header,
    .discover-section,
    .articles-section {
        margin-top: 40px;
        width: 100%;
    }

    .footer {
        margin-top: 40px;
    }
}
</style>