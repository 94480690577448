<template>
    <AboutComponent />
</template>
<script>
import AboutComponent from '@/components/builder/AboutComponent.vue'

export default {
    name: 'AboutPage',
    components: {
        AboutComponent,
    },
}
</script>