<template>
    <FeatureShowcaseComponent :features="digital_art.features" :sectionTitle="digital_art.sectionTitle"
        :sectionDescription="digital_art.sectionDescription" />
    <FeatureShowcaseComponent :features="discord_bot.features" :sectionTitle="discord_bot.sectionTitle"
        :sectionDescription="discord_bot.sectionDescription" />

    <DiscoverComponent :title="discover.title" :description="discover.description" :imageSrc="discover.imageSrc"
        :imageAlt="discover.imageAlt" />
    <CtaComponent :title="cta.title" :description="cta.description" />
</template>

<script>
import DiscoverComponent from '@/components/builder/DiscoverComponent.vue'
import CtaComponent from '@/components/builder/CtaComponent.vue'
import FeatureShowcaseComponent from '@/components/builder/FeatureShowcaseComponent.vue'

export default {
    name: 'HomePage',
    components: {
        DiscoverComponent,
        CtaComponent,
        FeatureShowcaseComponent,
    },
    data() {
        return {
            discover: {
                title: 'Discover the World of Digital Art and Unleash Your Creativity',
                description: '6static is a digital art marketplace that allows you to buy and sell digital art. We are a community of digital artists, collectors and enthusiasts. Join us and discover the world of digital art.',
                imageSrc: require('@/assets/PlaceholderImage.png'),
                imageAlt: 'Placeholder Image',
            },
            cta: {
                title: 'Unlock the Power of Discord Bots',
                description: 'Enhance your Discord server with our custom-built bots that automate tasks, engage your community, and provide unique experiences. Our team of experts will work closely with you to develop tailored solutions that meet your specific needs.',
            },
            digital_art: {
                sectionTitle: 'Digital Art',
                sectionDescription: 'Explore our curated collection of digital art products, handpicked for their creativity and uniqueness. Each piece is a masterpiece, ready to enhance your digital space.',
                features: [
                    {
                        title: 'New Arrivals',
                        description: 'Check out the latest additions to our digital art collection.'
                    },
                    {
                        title: 'Limited Editions',
                        description: 'Discover exclusive digital art pieces, available in limited quantities.'
                    },
                    {
                        title: 'Most Popular',
                        description: 'Browse our most popular digital art products, as chosen by our community.'
                    },
                ]
            },
            discord_bot: {
                sectionTitle: 'Discord Bots',
                sectionDescription: 'Discord bots are a great way to add some fun and functionality to your server. They can be used for moderation, music, games, and more! You can even create your own bot with our easy-to-use bot builder.',
                features: [
                    {
                        title: 'Music Bot',
                        description: 'woof'
                    },
                    {
                        title: 'Utility Bot',
                        description: 'meow'
                    },
                    {
                        title: 'Fun Bot',
                        description: 'bark'
                    },
                ]
            }
        }
    }
}
</script>