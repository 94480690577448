<template>
    <FeatureShowcaseComponent
        :features="discord_bot.features"
        :sectionTitle="discord_bot.sectionTitle"
        :sectionDescription="discord_bot.sectionDescription"
        :noMaxWidth="true"
        :imageWide="true" />
    <FeatureShowcaseComponent
        :sectionTitle="featureShowcase.sectionTitle"
        :sectionDescription="featureShowcase.sectionDescription"
        :features="featureShowcase.features" />
</template>
<script>
import FeatureShowcaseComponent from "@/components/builder/FeatureShowcaseComponent.vue"
export default {
    name: "BotServicesPage",
    components: {
        FeatureShowcaseComponent,
    },
    data() {
        return {
            featureShowcase: {
                sectionTitle:
                    "Discover the World of Digital Art and Unleash Your Creativity",
                sectionDescription: `At 6static, we offer a wide range of unique digital art products and services that cater to all your creative needs. Whether you're an artist looking to showcase your work or a buyer searching for one-of-a-kind pieces, our platform is designed to connect you with the best talent and artwork in the industry. Join our community today and experience the endless possibilities of digital art.`,
                features: [
                    {
                        title: "Efficient Coding",
                        description:
                            "Our team of experienced developers ensures that your Discord bot is built with efficient coding practices, resulting in smooth performance and minimal downtime.",
                    },
                    {
                        title: "Customizable Bots",
                        description: `Tailor your Discord bot to suit your server's unique needs and preferences. From custom commands to personalized features, we've got you covered.`,
                    },
                    {
                        title: "Reliable Support",
                        description: `We're here to support you every step of the way. Our dedicated team is available to assist with any questions or issues you may encounter.`,
                    },
                ],
            },
            discord_bot: {
                sectionTitle: "Discord Bots",
                sectionDescription:
                    "Discord bots are a great way to add some fun and functionality to your server. They can be used for moderation, music, games, and more! You can even create your own bot with our easy-to-use bot builder.",
                features: [
                    {
                        title: "Music Bot",
                        description: "BambiMusic",
                        imageSrc: require("@/assets/images/bambimusic_cover.jpg"),
                        imageAlt: "BambiMusic Cover Picture",
                    },
                    {
                        title: "Utility Bot",
                        description: "Miepus",
                        imageSrc: require("@/assets/images/miepus_cover.jpg"),
                        imageAlt: "Miepus Cover Picture",
                    },
                    {
                        title: "Leveling Bot",
                        description: "Nautikin Adventures",
                        imageSrc: require("@/assets/images/nautikin_adventures_cover.jpg"),
                        imageAlt: "Nautiking Adventures Bot Cover Picture",
                    },
                ],
            },
        }
    },
}
</script>