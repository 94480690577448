<template>
    <section class="main-container">
        <div class="text-container">
            <div class="header">
                <h1 class="title">{{ title }}</h1>
                <p class="description">{{ description }}</p>
            </div>
        </div>
        <div class="image-container">
            <button @click="handleButtonClick" class="image-button">
                <div class="image-wrapper">
                    <img loading="lazy" :src="imageSrc" :alt="imageAlt" class="image" />
                </div>
            </button>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: 'Discover the World of Digital Art and Unleash Your Creativity',
        },
        description: {
            type: String,
            required: true,
        },
        imageSrc: {
            type: String,
            required: true,
            default: require('@/assets/PlaceholderImage.png'),
        },
        imageAlt: {
            type: String,
            required: true,
            default: 'Placeholder Image',
        },
    },
    methods: {
        handleButtonClick() {
            // Add your event handler logic here
        },
    },
}
</script>

<style scoped>
.discover-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 3rem;
}

.text-container,
.image-container {
    width: 50%;
}

.header {
    width: 100%;
    margin: 112px 0 90px;
}

@media (max-width: 991px) {
    .text-container,
    .image-container {
        width: 100%;
    }

    .header {
        margin: 40px 0;
    }
}

.title {
    color: var(--primary-color, #000);
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 120%;
    font-family: var(--secondary-font-family, sans-serif);
    max-width: 100%;
}

.description {
    color: var(--primary-color, #000);
    margin-top: 24px;
    font-size: 1.125rem;
    line-height: 150%;
    font-family: var(--secondary-font-family, sans-serif);
    max-width: 100%;
}

.image-wrapper {
    /* padding-top: 96%; */
    /* Maintain aspect ratio 1:1.04 */
    aspect-ratio: 1 / 1.04;
    position: relative;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.image-button {
    width: 100%;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

@media (max-width: 991px) {
    .image-wrapper {
        padding-top: 60%;
        /* Adjusted aspect ratio for mobile view */
    }
}
</style>