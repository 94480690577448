<template>
    <section class="main-container">
        <div class="content-wrapper">
            <div class="column title-column">
                <div class="subheading">Innovative</div>
                <h1 class="title">Unlock the Power of Discord Bots</h1>
                <p class="description">
                    Enhance your Discord server with our custom-built bots...
                </p>
                <div class="cta-section">
                    <ButtonComponent label="Learn More" :isPrimary="true" @click="handleLearnMore" />
                    <SignUpButton :isPrimary="false" @click="handleSignUp" />
                </div>
            </div>
            <div class="column image-column">
                <img loading="lazy" :src="imageSrc || require('@/assets/PlaceholderImage.png')"
                    :alt="imageAlt || 'Placeholder Image'" />
            </div>
        </div>
    </section>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import SignUpButton from '@/components/SignUpButton.vue'

export default {
    name: 'CtaComponent',
    components: {
        ButtonComponent,
        SignUpButton,
    },
    props: {
        imageSrc: {
            type: String,
            default: '',
        },
        imageAlt: {
            type: String,
            default: '',
        },
    },
    methods: {
        handleLearnMore() {
            // Handle Learn More button click
        },
        handleSignUp() {
            // Handle Sign Up button click
        },
    },
}
</script>

<style scoped>
.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5rem;
}

.column {
    width: 50%;
}

.title-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.title {
    color: var(--primary-color);
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.description {
    color: var(--primary-color);
    font-size: 1.125rem;
    margin-bottom: 2rem;
}

.cta-section {
    display: flex;
    gap: 1rem;
}

.cta-button {
    padding: 12px 20px;
    border: none;
    background-color: var(--primary-color);
    color: var(--background-color);
    cursor: pointer;
    font-size: 1rem;
}

.image-column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-column img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media (max-width: 991px) {
    .content-wrapper {
        flex-direction: column;
        align-items: center;

    }

    .column {
        width: 100%;
    }
}
</style>